<template>
    <b-card no-body>
     
        
        <b-card-body class='pb-0 mb-0'>
            
            <b-card-title style="font-size: 20px; font-weight: 600; font-style: normal;">
                {{$t('charts.titles.btc')}}
            </b-card-title> 
            
            <b-row>
                <b-col>
                    <p>
                        <span class="text-black" style="font-size: 22px;">{{ btc_market_price.formatMoney(2,' ', ' ') }} </span>
                        <span v-if="market_price_diff" :class=" market_price_diff > 0 ? 'text-success' : 'text-danger' " style="font-size: 14px;">
                            <span v-if="market_price_diff > 0">+</span>{{ market_price_diff.formatMoney(2,' ', ' ') }}
                        </span>
                        <span v-if="market_price_percent" :class=" market_price_percent > 0 ? 'text-success' : 'text-danger' " style="font-size: 14px;">
                            (<span v-if="market_price_percent > 0">+</span>{{ market_price_percent }}%)
                        </span>
                    </p>
                </b-col>
            </b-row>
            
            <b-row>

                <b-col cols="12" class="d-flex justify-content-between">
                    <div class="d-flex tab-selection">
                        <div :class=" Charts.filters['market-price'].period === '1week' ? 'active' : '' " @click="setPeriod('1week')" :style="skin.value !== 'light' && Charts.filters['market-price'].period !== '1week' ? 'color: #fff' : ''">{{$t('charts.filters.1w')}}</div>
                        <div :class=" Charts.filters['market-price'].period === '1months' ? 'active' : '' " @click="setPeriod('1months')" :style="skin.value !== 'light' && Charts.filters['market-price'].period !== '1months' ? 'color: #fff' : ''">{{$t('charts.filters.1m')}}</div>
                        <div :class=" Charts.filters['market-price'].period === '3months' ? 'active' : '' " @click="setPeriod('3months')" :style="skin.value !== 'light' && Charts.filters['market-price'].period !== '3months' ? 'color: #fff' : ''">{{$t('charts.filters.3m')}}</div>
                        <div :class=" Charts.filters['market-price'].period === '6months' ? 'active' : '' " @click="setPeriod('6months')" :style="skin.value !== 'light' && Charts.filters['market-price'].period !== '6months' ? 'color: #fff' : ''">{{$t('charts.filters.6m')}}</div>
                        <div :class=" Charts.filters['market-price'].period === '1year' ? 'active' : '' " @click="setPeriod('1year')" :style="skin.value !== 'light' && Charts.filters['market-price'].period !== '1year' ? 'color: #fff' : ''">{{$t('charts.filters.1y')}}</div>
                        <div :class=" Charts.filters['market-price'].period === '5year' ? 'active' : '' " @click="setPeriod('5year')" :style="skin.value !== 'light' && Charts.filters['market-price'].period !== '5year' ? 'color: #fff' : ''">{{$t('charts.filters.5y')}}</div>
                    </div>
                </b-col>
                
            </b-row>
            
        </b-card-body>
        
        <vue-apex-charts
            type="area"
            height="auto"
            width="100%"
            :options="chartOptions"
            :series="Charts['market-price'].series"
        />
        
    </b-card>
</template>

<script>

    import Charts from "@/modules/charts"
    import { $themeColors } from '@themeConfig'
    import VueApexCharts from 'vue-apexcharts'
    import Blockchain from "@/modules/blockchain"
    import useAppConfig from '@core/app-config/useAppConfig'
    
    export default {

        data() {
            return {
                
                Charts,
                skin: useAppConfig().skin,
                is_can_show_graphic: true,
                
                market_price_percent: null,
                market_price_diff: null,
                
                
            }
        },
        methods: {
            setPeriod( value ) {
                
                  Charts.filters['market-price'].period = value;
                  
                  Charts.update('market-price').then( result => {
                      
                      let firstValue = result[0].y;
                      let lastValue = result[ result.length - 1 ].y;
                      
                      this.market_price_percent = ((( lastValue - firstValue ) / firstValue) * 100).toFixed(2);
                      this.market_price_diff = lastValue - firstValue;
                      
                  });
                  
            },
        },
        
        computed: {
            btc_market_price() {
                return Blockchain.tickers.values['USD'].last;               
            },  
            chartOptions() {
                return {
                    chart: {
                        animations: {
                             enabled: true
                        },
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: false,
                        },
                        zoom: {
                            enabled: true,
                            type: 'x',
                            autoScaleYaxis: false
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: "#EBECF2",
                        strokeDashArray: 3,
                        position: 'front',
                        column: {
                            colors: [ "#FFF" ],
                            opacity: 0.1
                        },
                        row: {
                            colors: [ "#EBECF2" ],
                            opacity: 0.1
                        },
                        padding: {
                          left: 10,
                          right: 0,
                        },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                        width: 2,
                        curve: 'smooth',
                    },
                    theme: { 
                        monochrome: {
                            enabled: false,
                            color: $themeColors.success,
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.9,
                            opacityFrom: 0.5,
                            opacityTo: 0.2,
                            stops: [0, 80, 100],
                            gradientToColors: [ $themeColors.success ],
                        },
                    },
                    colors: [ $themeColors.success ],
                    xaxis: {
                        categories: Charts['market-price'].labels,
                        type: 'category',
                        labels: {
                            show: true,
                            rotate: -10,
                            trim: true,
                            style: {
                                fontSize: "8px",
                                colors: this.skin.value === 'light' ? "#000" : "#FFF",
                            },
                            formatter: ( value ) => {
                                if( !value ) return "";
                                var date = new Date(value *  1000);
                                // var day = new Intl.DateTimeFormat('ru', {  day: 'numeric' }).format(date);
                                var formatted = new Intl.DateTimeFormat('ru', {  day: 'numeric', year: '2-digit', month: 'numeric' }).format(date);
                                                                                                
                                return formatted;
                                
                            },
                            format: 'dd/MM'
                        },
                    },
                    yaxis: {
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: true,
                            formatter: ( value ) => {
                                return value.formatMoney(2,' ', ' ')
                            },
                            style: {
                                colors: this.skin.value === 'light' ? "#000" : "#FFF",
                            },
                        }
                    },
                    tooltip: {
                        x: { 
                            show: false 
                        },
                        y: {
                            title: {
                                formatter: (seriesName) => "$"
                            },
                        },
                    },
                }
            }        

        },
        
        components: {
            VueApexCharts
        },
        watch: {
            
        },
        mounted() {
            
            // let data = Charts['market-price'].series[0].data;

            // let firstValue = data[0];
            // let lastValue = data[ data.length - 1 ];

            
            // this.market_price_percent = ((( lastValue - firstValue ) / firstValue) * 100).toFixed(2);
            // this.market_price_diff = lastValue - firstValue;
                
            Charts.filters['market-price'].period = '3months';
                  
            Charts.update('market-price').then( result => {
                
                let firstValue = result[0].y;
                let lastValue = result[ result.length - 1 ].y;
                
                this.market_price_percent = ((( lastValue - firstValue ) / firstValue) * 100).toFixed(2);
                this.market_price_diff = lastValue - firstValue;
                
            });
        }

    }

</script>
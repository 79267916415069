<template>
    <div>
        <dashboard-btc-graph v-if="selectedTabIndex === 1" />
        <dashboard-diff-graph v-if="selectedTabIndex === 2" />
        <dashboard-minires-graph v-if="selectedTabIndex === 3" />
    </div>
</template>

<script>

    import DashboardBtcGraph from "./charts/dashboard-btc-graph"
    import DashboardDiffGraph from "./charts/dashboard-diff-graph"
    import DashboardMiniresGraph from "./charts/dashboard-minires-graph"

    export default {

        data() {
            return {}
        },
        methods: {

        },
        
        computed: {
            selectedTabIndex() {
                
                switch( this.$route.params.name ) {
                    case 'market-price': return 1;
                        case 'difficulty': return 2;
                            case 'miners-revenue': return 3;
                }
                
                return -1;
            }
        },
        
        components: {
            DashboardBtcGraph,
            DashboardDiffGraph,
            DashboardMiniresGraph,
        },
        watch: {

        },
        mounted() {

        }

    }

</script>